import axios from "axios"
import qs from "qs"

const SERVICE_TYPES: { [name: string]: { searchType: string; path: string } } = {
	오피스텔: {
		searchType: "officetel",
		path: "/home/officetel/map",
	},
	빌라: {
		searchType: "villa",
		path: "/home/villa/map",
	},
	원룸: {
		searchType: "oneroom",
		path: "/home/oneroom/map",
	},
	아파트: {
		searchType: "apartment",
		path: "/home/apt/map",
	},
	상가: {
		searchType: "store",
		path: "/home/store/map",
	},
	분양: {
		searchType: "offer",
		path: "/home/apt/map/offer",
	},
	아파트매물: {
		searchType: "apartment_maemul",
		path: "/home/apt/items",
	},
}

const EXCLUDE_WORDS = ["시세", "계획", "일정", "정보", "단기", "주변", "근처", "예정", "단지", "신축", "풀옵션", "가격"]

const SALES_TYPE_MAP: Map<string, string[]> = new Map([
	["매매", ["매매", "매매가"]],
	["전세", ["전세", "전세가"]],
	["월세", ["월세", "월세가"]],
])

const SERVICE_WORDS_MAP: Map<string, string[]> = new Map([
	[SERVICE_TYPES.오피스텔.searchType, ["오피스텔"]],
	[SERVICE_TYPES.빌라.searchType, ["빌라", "투룸", "다세대", "주택"]],
	[SERVICE_TYPES.원룸.searchType, ["원룸"]],
	[SERVICE_TYPES.아파트.searchType, ["아파트"]],
	[SERVICE_TYPES.상가.searchType, ["상가"]],
	[
		SERVICE_TYPES.분양.searchType,
		[
			"분양",
			"미분양",
			"분양가",
			"분양권",
			"청약중",
			"청약권",
			"분양일정",
			"분양계획",
			"분양예정",
			"분양정보",
			"분양가격",
			"분양아파트",
			"미분양아파트",
		],
	],
])

interface SearchFactor {
	path: string
	point: {
		lat: number | null
		lng: number | null
	}
	zoomLevel: number | null
	filter: {
		salesType: string | null
	}
	localCode: string
}

export class SearchQueryAnalyst {
	static readonly MAX_ZIGBANG_SEARCH_WORDS_LENGTH = 5
	static readonly ZIGBANG_SEARCH_URL = "http://apis.zigbang.com/search"
	static readonly ZIGBANG_BSEARCH_URL = "http://apis.zigbang.com/search/address"
	// static readonly ZIGBANG_BSEARCH_URL = "http://apis-preview.zigbang.net/search/address"
	static readonly ZIGBANG_SEARCH_DEFAULT_CATEGORY = ["address", "subway", "school"]
	static readonly DEFAULT_PATH = "/"
	static readonly DEFAULT_SERVICE_TYPE = "apartment"

	static async start(query: string, q: any) {
		try {
			const searchFactor = await this.makeSearchFactor(query, q)
			return this.makeURL(searchFactor)
		} catch (e) {
			try {
				const errLogs = this.makeErrorLog({ errMessage: e, query, q })

				const url = `${process.env.APIS_HOST}/v2/logs/kakao`

				axios.post(url, { log: errLogs })
			} catch (err) {}

			return this.DEFAULT_PATH
		}
	}

	static async makeSearchFactor(query: string, q: any): Promise<SearchFactor> {
		if (!query) {
			throw new Error("need parameter query")
		}
		if (!query && !q.ext_query && !q.service_type) {
			throw new Error("need parameter (query or ext_query or service_type)")
		}

		const words: string[] = this.get통검_사용자입력값s({ query, ext_query: q.ext_query }) ?? []
		const service_type: string = this.makeSearchType(q)
		const sales_type: string = this.makeSalesType(q)
		const target_bcode: string = this.makeTargetBCode(q)

		// 법정동코드가 있는 경우
		// https://m.realty.daum.net/search/analysis?&query=오금동+아파트+전세&ext_query=오금동+아파트+전세&dealtypecode=L&service_type=A1&lcode=&house_yn=Y&channel_type=maemul&target_bcode=1171011200&region1=서울&region2=송파구&region3=오금동

		// 법정동코드가 없는 경우
		// https://m.realty.daum.net/search/analysis?&query=당산1동+아파트&ext_query=당산1동+아파트&dealtypecode=&service_type=A1&lcode=&channel_type=danji&target_bcode=&region1=서울&region2=영등포구&region3=당산동1가
		// let city_name: string = ""
		// let address_name: string = ""

		let filteredWordsByExcludeWords
		if (target_bcode) {
			filteredWordsByExcludeWords = this.filterWords(words)
		} else if (!q.region1 && !q.region2 && !q.region3) {
			filteredWordsByExcludeWords = this.filterWords(words)
		} else {
			let region_data
			let region_words
			let is_region1: boolean = false
			let is_region2: boolean = false
			if (q.region1 && q.region2 && q.region3) {
				region_data = `${q.region2}+${q.region3}`
				region_words = region_data.split("+")
			} else if (q.region1 && q.region2) {
				region_data = `${q.region1}+${q.region2}`
				region_words = region_data.split("+")
			} else if (q.region1) {
				region_data = q.region1
				region_words = region_data

				is_region1 = true
			}

			if (q.region2 && q.region2.indexOf(" ") >= 0) {
				is_region2 = true
			}

			if (is_region2) {
				if (q.region3) {
					filteredWordsByExcludeWords = this.filterWords(words)
					if (filteredWordsByExcludeWords.length >= 4) {
						filteredWordsByExcludeWords = filteredWordsByExcludeWords.slice(
							filteredWordsByExcludeWords.length - 4 + 1,
							filteredWordsByExcludeWords.length
						)
					}
				}
			} else if (!is_region1) {
				filteredWordsByExcludeWords = this.filterWords(region_words)
			} else {
				filteredWordsByExcludeWords = this.filterWords(words)
			}
		}

		// eslint-disable-next-line prefer-const
		let [serviceType, filteredWordsByServiceTypes] = this.getServiceByQueryWords(filteredWordsByExcludeWords)
		if (service_type !== null && service_type !== "") {
			serviceType = service_type
		}

		// eslint-disable-next-line prefer-const
		let [salesType, filteredWordsBySalesTypes] = this.getSalesTypeByQueryWords(filteredWordsByServiceTypes)
		if (sales_type !== null && sales_type !== "") {
			salesType = sales_type
		}

		let searchResult = await this.searchByZigbangSearch(filteredWordsBySalesTypes, serviceType, target_bcode)
		let firstSearchResult: string | { [key: string]: any } = ""
		if (target_bcode && target_bcode !== "") {
			firstSearchResult = searchResult.pop()

			if (!firstSearchResult || firstSearchResult === "") {
				const target_breplace = `${target_bcode.slice(0, target_bcode.length - 2)}00`

				searchResult = await this.searchByZigbangSearch(filteredWordsBySalesTypes, serviceType, target_breplace)
				firstSearchResult = searchResult.pop()

				if (!firstSearchResult || firstSearchResult === "") {
					const researchResult = await this.searchByZigbangSearch(filteredWordsBySalesTypes, serviceType, "")
					firstSearchResult = researchResult.pop()
				}
			}
		} else if (!q.region1 && !q.region2 && !q.region3) {
			firstSearchResult = searchResult.pop()
		} else {
			searchResult.forEach((mapObject) => {
				const { _source } = mapObject

				if (q.region1 && q.region2 && q.region3) {
					if (_source.local2 === q.region2 && _source.local3 === q.region3) {
						firstSearchResult = mapObject
					}
				} else if (q.region1 && q.region2) {
					if (_source.local1.length === q.region1.length) {
						if (_source.local1 === q.region1 && _source.local2 === q.region2) {
							firstSearchResult = mapObject
						}
					} else {
						const local1_length = _source.local1.length
						const region1_length = q.region1.length

						if (local1_length > region1_length) {
							if (_source.local1.indexOf(q.region1) > -1 && _source.local2 === q.region2) {
								firstSearchResult = mapObject
							}
						} else if (q.region1.indexOf(_source.local1) > -1 && _source.local2 === q.region2) {
							firstSearchResult = mapObject
						}
					}
				} else if (q.region1) {
					if (_source.local1.length === q.region1.length) {
						if (_source.local1 === q.region1) {
							firstSearchResult = mapObject
						}
					} else {
						const local1_length = _source.local1.length
						const region1_length = q.region1.length

						if (local1_length > region1_length) {
							if (_source.local1.indexOf(q.region1) > -1) {
								firstSearchResult = mapObject
							}
						} else if (q.region1.indexOf(_source.local1) > -1) {
							firstSearchResult = mapObject
						}
					}
				}
			})
		}
		const firstSearchResultObj = typeof firstSearchResult === "string" ? undefined : firstSearchResult
		let localCode = ""
		if (
			// local2 부터 있어야 매물 페이지로 이동
			q.region2 !== "" &&
			serviceType === SERVICE_TYPES.아파트.searchType &&
			q.channel_type &&
			q.channel_type.indexOf("maemul") >= 0
		) {
			// local2 가 시군구 인데 군구인 경우에만 매물 /apt/items 로 이동
			// 이걸 확인하기 위해서는 직방 검색결과의 법정동 코드와 쿼리의 법정동 코드가 같은지 확인하면 됨
			if (q.target_bcode === firstSearchResultObj?._source?.법정동코드) {
				serviceType = SERVICE_TYPES.아파트매물.searchType
				if (q.region3 !== "") {
					localCode = q.target_bcode.slice(0, 8)
				} else {
					localCode = q.target_bcode.slice(0, 5)
				}
			}
		}

		let zoom_level: number = 5
		if (
			serviceType === "apartment" ||
			serviceType === "officetel" ||
			serviceType === "oneroom" ||
			serviceType === "villa"
		) {
			if (q.region1 && q.region2 && q.region3) {
				zoom_level = 5
			} else if (q.region1 && q.region2) {
				zoom_level = 6
			} else if (q.region1) {
				if (service_type === "officetel") {
					zoom_level = 7
				} else {
					zoom_level = 9
				}
			}
		}
		return {
			path: this.getPathBySearchType(serviceType || firstSearchResultObj?.type),
			point: {
				lat: firstSearchResultObj?.lat ?? null,
				lng: firstSearchResultObj?.lng ?? null,
			},
			// zoomLevel: _.get(firstSearchResult, "zoom_level.daum", null),
			zoomLevel: zoom_level,
			filter: {
				salesType,
			},
			localCode,
		}
	}
	static zoomToLatLngDelta(
		lat: number,
		zoom: number,
		mapWidth: number,
		mapAspectRatio: number
	): { latitudeDelta: number; longitudeDelta: number } {
		const longitudeDelta = ((360 / 256) * mapWidth) / Math.pow(2, zoom)
		const latitudeDelta = (longitudeDelta / mapAspectRatio) * Math.cos((lat * Math.PI) / 180)

		return { latitudeDelta, longitudeDelta }
	}
	static makeURL(searchFactor: SearchFactor): string {
		if (
			[searchFactor.point.lat, searchFactor.point.lng, searchFactor.zoomLevel].some(
				(v) => v === null || v === undefined
			)
		) {
			return this.DEFAULT_PATH
		}

		// https://m.realty.daum.net/home/villa/items?lat_south=37.50091490881097&lat_north=37.52130859903457&lng_west=127.02664423964103&lng_east=127.04361975525444&zoom=15

		if (searchFactor.path.indexOf("apt") > 0) {
			if (searchFactor.path.indexOf("items") > 0) {
				return `${searchFactor.path}/${searchFactor.localCode}?_preload[]=${encodeURIComponent("/")}`
			}
			return `${searchFactor.path}?${qs.stringify({
				latitude: searchFactor?.point?.lat,
				longitude: searchFactor?.point?.lng,
				zoom: searchFactor?.zoomLevel,
				sales_type: searchFactor?.filter?.salesType,
			})}&_preload[]=${encodeURIComponent("/")}`
		}

		let uri: string = ""
		if (searchFactor.path.indexOf("villa") > 0) {
			uri = "/home/villa/items"
		} else if (searchFactor.path.indexOf("oneroom") > 0) {
			uri = "/home/oneroom/items"
		} else if (searchFactor.path.indexOf("officetel") > 0) {
			uri = "/home/officetel/items"
		} else if (searchFactor.path.indexOf("store") > 0) {
			uri = "/home/store/items"
		}

		// Local1 zoom level 9
		// Local2 zoom level 6
		// Local3 zoom level 5
		const latitude_value = searchFactor?.point?.lat ?? 0
		const Longitude_value = searchFactor?.point?.lng ?? 0
		const zoom_value = searchFactor?.zoomLevel
		const view_dimemsion_width = 400
		const view_dimemsion_height = 800

		// if (searchFactor.path.indexOf("officetel") > 0) {
		// 	zoom_value = 7
		// }

		const { latitudeDelta, longitudeDelta } = this.zoomToLatLngDelta(
			latitude_value,
			20 - (zoom_value ?? 0),
			view_dimemsion_width,
			view_dimemsion_width / view_dimemsion_height
		)

		const lat_south_value = latitude_value - latitudeDelta / 2
		const lat_north_value = latitude_value + latitudeDelta / 2
		const lng_west_value = Longitude_value - longitudeDelta / 2
		const lng_east_value = Longitude_value + longitudeDelta / 2

		return `${uri}?${qs.stringify({
			lat_south: lat_south_value,
			lat_north: lat_north_value,
			lng_west: lng_west_value,
			lng_east: lng_east_value,
			zoom: zoom_value,
			need_more_zoom_in: false,
			salesType: searchFactor?.filter?.salesType,
		})}&_preload[]=${encodeURIComponent(
			`${searchFactor.path}?${qs.stringify({
				latitude: latitude_value,
				longitude: Longitude_value,
				zoom: zoom_value,
			})}`
		)}&_preload[]=${encodeURIComponent("/")}`
	}
	static async searchByZigbangSearch(words: string[], serviceType: string, target_bcode: string) {
		const loopNumber =
			words.length > this.MAX_ZIGBANG_SEARCH_WORDS_LENGTH ? this.MAX_ZIGBANG_SEARCH_WORDS_LENGTH : words.length
		const joinWords: string[] = []
		if (target_bcode && target_bcode !== "") {
			joinWords.push(target_bcode)
		} else {
			for (let i = 1; i <= loopNumber; i++) {
				joinWords.push(words.slice(0, i).join(" "))
			}
		}

		const serviceTypesForQueryString = [...this.ZIGBANG_SEARCH_DEFAULT_CATEGORY]

		switch (serviceType) {
			case SERVICE_TYPES.아파트.searchType:
			case SERVICE_TYPES.분양.searchType:
			case SERVICE_TYPES.아파트매물.searchType:
				serviceTypesForQueryString.push(SERVICE_TYPES.아파트.searchType)
				break
			case SERVICE_TYPES.오피스텔.searchType:
				serviceTypesForQueryString.push(SERVICE_TYPES.오피스텔.searchType)
				break
			default:
				serviceTypesForQueryString.push(SERVICE_TYPES.아파트.searchType)
				serviceTypesForQueryString.push(SERVICE_TYPES.오피스텔.searchType)
		}

		if (target_bcode && target_bcode !== "") {
			const Results = await Promise.all(
				joinWords.map((word) =>
					axios.get(`${this.ZIGBANG_BSEARCH_URL}?${qs.stringify({ bjd: word }, { arrayFormat: "indices" })}`)
				)
			)
			return Results.map((result) => result?.data?.items?.[0])?.filter((result) => result)
		}

		const getResults = await Promise.all(
			joinWords.map((word) =>
				axios.get(
					`${this.ZIGBANG_SEARCH_URL}?${qs.stringify(
						{ searchType: serviceTypesForQueryString, q: word },
						{ arrayFormat: "indices" }
					)}`
				)
			)
		)
		return getResults.map((result) => result?.data?.items?.[0])?.filter((result) => result)
	}
	private static getServiceByQueryWords(words: string[]): [string, string[]] {
		const [serviceType, filteredWords] = this.filterWordsByMaps(words, SERVICE_WORDS_MAP)
		return [serviceType || this.DEFAULT_SERVICE_TYPE, filteredWords]
	}
	private static getPathBySearchType(searchType: string): string {
		for (const key of Object.keys(SERVICE_TYPES)) {
			if ((SERVICE_TYPES[key.toString()].searchType as string) === searchType) {
				return SERVICE_TYPES[key.toString()].path
			}
		}

		return SERVICE_TYPES[this.DEFAULT_SERVICE_TYPE].path
	}
	private static getSalesTypeByQueryWords(words: string[]): [string | null, string[]] {
		const [salesType, filteredWords] = this.filterWordsByMaps(words, SALES_TYPE_MAP)
		return [salesType, filteredWords]
	}
	private static filterWords(words: string[]): string[] {
		return difference(words, EXCLUDE_WORDS)
	}
	private static filterWordsByMaps(words: string[], wordsMap: Map<string, string[]>): [string | null, string[]] {
		let selectedKey: string | null = null
		let filteredWords = words

		for (const [key, value] of Array.from(wordsMap.entries())) {
			const previousFilteringLength = filteredWords.length
			filteredWords = difference(filteredWords, value)
			if (previousFilteringLength !== filteredWords.length) {
				selectedKey = key
			}
		}

		return [selectedKey, filteredWords]
	}
	private static get통검_사용자입력값s({ query, ext_query }): string[] {
		if (query) {
			return query.split(" ")
		}
		if (ext_query) {
			return ext_query.split(" ")
		}
		return []
	}
	private static makeSearchType(q: any) {
		let service_type: string = ""

		if (q.service_type) {
			// 아파트 (A1) / 오피스텔 (A6) / 원룸 (C1) / 빌라: 다세대 (CE) , 단독 (CF) 으로 구분
			if (q.service_type.indexOf("A1") >= 0) {
				service_type = "apartment"
			} else if (q.service_type.indexOf("A6") >= 0) {
				service_type = "officetel"
			} else if (q.service_type.indexOf("C1") >= 0) {
				service_type = "oneroom"
			} else if (
				q.service_type.indexOf("CE") >= 0 ||
				q.service_type.indexOf("CF") >= 0 ||
				q.service_type.indexOf("CDCE") >= 0
			) {
				service_type = "villa"
			} else if (q.service_type.indexOf("C8") >= 0) {
				service_type = "store"
			} else {
				service_type = "apartment"
			}
		}

		if (q.channel_type && q.channel_type.indexOf("bunyangdanji") >= 0) {
			service_type = "offer"
		}

		if (q.query && (q.query.indexOf("투룸") >= 0 || q.query.indexOf("쓰리룸") >= 0)) {
			service_type = "villa"
		}

		return service_type
	}
	private static makeSalesType(q: any) {
		let sales_type: string = ""

		if (q.dealtypecode) {
			// 매매 (S) / 월세 (R) / 전세 (L)
			if (q.dealtypecode.indexOf("S") >= 0) {
				sales_type = "매매"
			} else if (q.dealtypecode.indexOf("R") >= 0) {
				sales_type = "월세"
			} else if (q.dealtypecode.indexOf("L") >= 0) {
				sales_type = "전세"
			}
		}

		return sales_type
	}

	private static makeErrorLog({ errMessage, query, q = {} }: { errMessage: string; query: string; q: any }): string {
		const { service_type, target_bcode, ext_query, region1, region2, region3, dealtypecode } = q

		return JSON.stringify({
			errMessage,
			service_type,
			target_bcode,
			query,
			ext_query,
			region1,
			region2,
			region3,
			dealtypecode,
			platform: "www",
		})
	}
	private static makeTargetBCode(q: any) {
		let target_bcode: string = ""
		if (q.target_bcode) {
			target_bcode = q.target_bcode
		}

		return target_bcode
	}
}

const difference = <T>(arr1: T[], arr2: T[]): T[] => arr1.filter((x) => !arr2.includes(x))
